import React from "react"
import NewsHeader from "../components/blog/blogHeader"
import { Link } from "gatsby"
import Footer from "../components/footer"
import SEO from "../components/seo"

import arrow from "../../assets/images/servicesPages/arrowDown.png"
import icon from "../../assets/images/blog/icon.png"
import sofia from "../../assets/images/blog/sofia.png"
import yahoo from "../../assets/images/blog/yahoo.png"
import abc from "../../assets/images/blog/abc-min.png"
import ida from "../../assets/images/blog/ida-min.png"
import roi from "../../assets/images/blog/roi-min.png"
import sofiaTeam from "../../assets/images/blog/sofia_team-min.png"
import security from "../../assets/images/blog/security-min.jpg"
import capturingLead from "../../assets/images/blog/capturing-lead-min.png"
import idDay from "../../assets/images/blog/id_day-min.png"
import ourApproach from "../../assets/images/blog/tht-min.png"
import iconBG from "../../assets/images/blog/icon-bg-min.jpg"
import theFuture from "../../assets/images/blog/the-future-teaser.png"
import AlConnects from "../../assets/images/blog/Al-Connects-the-dots-for-digital-diagnosis.png"
import Sams from "../../assets/images/blog/sams/teaser.png"
import ZielAbi from "../../assets/images/blog/zielabi/ziel-thumb.png"
import insights from "../../assets/images/blog/insights/insights.png"
import invest from "../../assets/images/blog/invest/invest.jpg"
import in_house from "../../assets/images/blog/in_house/in_house.jpg"
import drupal_7 from "../../assets/images/blog/drupal_7/drupal_7.jpg"
import ecomerce from "../../assets/images/blog/ecomerce/ecomerce.jpg"
import development from "../../assets/images/blog/development/development.jpg"
import ecomerceBest from "../../assets/images/blog/ecomerce-best/ecomerce-best.jpg"
import vs1 from "../../assets/images/blog/magento_vs_woo/magento_vs_woo1.jpg"
import seo from "../../assets/images/blog/seo/seo1.jpg"
import zeroParty from "../../assets/images/blog/zero-party/image1.jpg"
import bitcoin from "../../assets/images/blog/bitcoin/image1.jpg"
import bulgaria from "../../assets/images/blog/bulgaria/image1.jpg"
import upgrateDrupal from "../../assets/images/blog/drupal_upgrate/image1.jpg"
import defi from "../../assets/images/blog/defi/defi.jpg"
import crypto from "../../assets/images/blog/crypto-casino/image1.png"
import drupal10 from "../../assets/images/blog/drupal10/image1.png"
import iconOpenAi from "../../assets/images/blog/icon-open-ai/icon-open-ai.png"
import drupalVsMagnolia from "../../assets/images/blog/drupal_vs_magnolia/drupal_vs_magnolia.png"
import risksOfNotUpdatingToDrupal10 from "../../assets/images/blog/risks_of_not_updating_to_drupal_10/risks_of_not_updating.png"

export default () =>
<div>
    <SEO title={'ICON Worldwide Blog | Nearshore Software Development, Web Design and Tech'}
    description="Nearshore Software Development, Web Design and Technology Blog: read all the best topics in the business market: from brilliant developers, to innovative companies!"
    canonical={'https://icon-worldwide.com/blog'} />

    <NewsHeader/>

    <div id="news">
        <div id="news-inner">

            <h1>Nearshore Software Development, Web Design and Tech: discover the best topics in your business market</h1>
            <div className="blog-post">
                <Link className="article" to="/blog/risks-of-not-updating-to-drupal-10">
                    <img src={risksOfNotUpdatingToDrupal10} className="article-img article-img-height"  alt="The Risks of Not Upgrading to Drupal 10: Ensuring Future Stability and Security" title="The Risks of Not Upgrading to Drupal 10: Ensuring Future Stability and Security"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">06 September 2023</div>
                    <h5>The Risks of Not Upgrading to Drupal 10.</h5>
                    <p>Upgrading to Drupal 10 is not only a technological necessity but a critical step in safeguarding websites against a host of security vulnerabilities ...</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/drupal-vs-magnolia">
                    <img src={drupalVsMagnolia} className="article-img article-img-height"  alt="Drupal vs. Magnolia CMS: Choosing Your Digital Powerhouse" title="Drupal vs. Magnolia CMS: Choosing Your Digital Powerhouse"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">09 August 2023</div>
                    <h5>Drupal vs. Magnolia: Choosing the Right CMS.</h5>
                    <p>In this article we analyze the fundamental differences between two popular CMSs, the Open Source Drupal CMS and License based Magnolia CMS ...</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/icon-open-ai-chat">
                    <img src={iconOpenAi} className="article-img article-img-height"  alt="Ready for Drupal 10? Check Your Sites Readiness for 8 New Features." title="Ready for Drupal 10? Check Your Sites Readiness for 8 New Features."/>
                    <div className="yellow-line"></div>
                    <div className="article-date">17 May 2023</div>
                    <h5>ICON Worldwide Develops Drupal OpenAI Integration</h5>
                    <p>OpenAI and ChatGPT have dominated the discussion this year from multiple perspectives including social and economic implications. ...</p>
                </Link>
            </div>

            <div className="blog-post">
                <Link className="article" to="/blog/drupal10">
                    <img src={drupal10} className="article-img article-img-height"  alt="Ready for Drupal 10? Check Your Sites Readiness for 8 New Features." title="Ready for Drupal 10? Check Your Sites Readiness for 8 New Features."/>
                    <div className="yellow-line"></div>
                    <div className="article-date">27 October 2022</div>
                    <h5>Ready for Drupal 10? Check Your Sites Readiness for 8 New Features.</h5>
                    <p>In December, 2022 Drupal will have another major release. As Drupal developers maintaining platforms for many organizations, big and small...</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/crypto-casino">
                    <img src={crypto} className="article-img article-img-height"  alt="Web3 and Crypto Casinos" title="Web3 and Crypto Casinos"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">20 October 2022</div>
                    <h5>Web3 & The €150 Billion Crypto Gambling Business</h5>
                    <p> Web3 is a future, decentralized phase of internet development, where users become owners.</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/decentralised-finance-2022">
                    <img src={defi} className="article-img article-img-height"  alt="Decentralised Finance 2022 and how it is defining the future of finance and technology" title="Decentralised Finance 2022 and how it is defining the future of finance and technology"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">13 April 2022</div>
                    <h5>Decentralised Finance 2022 and how it is defining the future of finance and technology</h5>
                    <p>Why Is 2022 the Year in Which DeFi Products Go Mainstream?</p>
                </Link>
            </div>

            <div className="blog-post">
                <Link className="article" to="/blog/nearshoring-bulgaria">
                    <img src={bulgaria} className="article-img article-img-height"  alt="Bulgaria As The Perfect Nearshore Development Destination [2021 GUIDE]" title="Bulgaria As The Perfect Nearshore Development Destination [2021 GUIDE]"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">28 January 2022</div>
                    <h5>Bulgaria As The Perfect Nearshore Development Destination [2022 GUIDE]</h5>
                    <p>Investing in nearshore software development has become the future of business. Whether it is the ongoing Covid-19 crisis...</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/upgrade-drupal-9">
                    <img src={upgrateDrupal} className="article-img article-img-height"  alt="Upgrade from Drupal 8 to Drupal 9" title="Upgrade from Drupal 8 to Drupal 9"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">16 June 2021</div>
                    <h5>Upgrade from Drupal 8 to Drupal 9</h5>
                    <p>Over 1 million websites around the world run on Drupal, from high tech companies, to government, NGO’s, and ...</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/how-to-invest-in-bitcoin">
                    <img src={bitcoin} className="article-img article-img-height"  alt="How To Invest In Bitcoin [In 2021]" title="How To Invest In Bitcoin [In 2021]"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">19 January 2021</div>
                    <h5>How To Invest In Bitcoin [In 2021]</h5>
                    <p>By now, we all know the radical history of Bitcoin, which emerged in 2008 in the middle of that year’s big economic crisis.</p>
                </Link>
            </div>
            
            <div className="blog-post">
                <Link className="article" to="/blog/zero-party-data-healthcare-industry">
                    <img src={zeroParty} className="article-img article-img-height"  alt="How Zero Party Data Is Affecting The Healthcare Industry in 2021" title="How Zero Party Data Is Affecting The Healthcare Industry in 2021"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">4 January 2021</div>
                    <h5>How Zero Party Data Is Affecting The Healthcare Industry in 2021</h5>
                    <p>Why are healthcare companies increasingly talking about Zero Party Data, and what is it?</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/seo-best-practices">
                    <img src={seo} className="article-img article-img-height"  alt="SEO Best Practices in 2021: The Definitive How-To Guide" title="SEO Best Practices in 2020: The Definitive How-To Guide"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">8 October 2020</div>
                    <h5>SEO Best Practices in 2021: The Definitive How-To Guide</h5>
                    <p>In 2020, developing a cutting-edge website is no longer enough to be found by your buyer persona: in fact, the truth is that you absolutely need to rank in Search Engines’ first pages for your focus keywords.</p>
                </Link>
            </div>
            
            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/magento-vs-woocommerce">
                    <img src={vs1} className="article-img article-img-height"  alt="Magento Vs WooCommerce: Which Is Right For Your Business?" title="Magento Vs WooCommerce: Which Is Right For Your Business?"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">16 September 2020</div>
                    <h5>Magento Vs WooCommerce: Which Is Right For Your Business?</h5>
                    <p>Magento Vs WooCommerce: two of the most used e-commerce website solutions on the market, between which it’s difficult to make an informed and professional choice.</p>
                </Link>
            </div>
          
            <div className="blog-post">
                <Link className="article" to="/blog/top-ecommerce-best-practices">
                    <img src={ecomerceBest} className="article-img article-img-height"  alt="Top 2021 Ecommerce Best Practices" title="Top 2021 Ecommerce Best Practices"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">31 August 2020</div>
                    <h5>Top E-Commerce Best Practices for 2021</h5>
                    <p>While many sectors of the global economy are clearly suffering, e-commerce has emerged as a life-line for many businesses.</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/project-development-model">
                    <img src={development} className="article-img article-img-height"  alt="ICON Worldwide’s Website Development Process" title="ICON Worldwide’s Website Development Process"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">19 August 2020</div>
                    <h5>ICON Worldwide’s Website Development Process</h5>
                    <p>In a recent discussion with a then “prospective”, now “new client”, I was pleased to hear him say... </p>
                </Link>
            </div>
          
            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/ecommerce-trends-2020">
                    <img src={ecomerce} className="article-img article-img-height"  alt="7 E-Commerce Trends To Watch in 2021" title="7 E-Commerce Trends To Watch in 2021"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">18 August 2020</div>
                    <h5>7 E-Commerce Trends To Watch in 2021</h5>
                    <p>By 2040, more than 95% of all purchases will be conducted via ecommerce?</p>
                </Link>
            </div>
           
            <div className="blog-post">
                <Link className="article" to="/blog/drupal-7-end-of-life">
                    <img src={drupal_7} className="article-img article-img-height"  alt="Drupal 7 End of Life" title="ICON, Drupal 7 End of Life"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">30 July 2020</div>
                    <h5>Drupal 7 End of Life: Manage Your Upgrade to Drupal 8 and 9 [+ FREE Site Migration Checklist]</h5>
                    <p>Drupal is one of the most widely used content management systems worldwide.</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/inhouse-vs-nearshoresoftware-development-team">
                    <img src={in_house} className="article-img article-img-height" alt="Nearshore software development, ICON Worldwide Blog, In-house Vs Nearshore Software Development Team" title="ICON Worldwide Blog, Nearshore software development, In-house Vs Nearshore Software Development Team"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">16 July 2020</div>
                    <h5>In-house Vs Nearshore Software Development Team: What's the Best Option for Your Enterprise?</h5>
                    <p>Are you considering in-house versus nearshore development, but are not sure about the core differences between those two development practices?</p>
                </Link>
            </div>
           
            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/why-invest-in-nearshore-software-development">
                    <img src={invest} className="article-img article-img-height" alt="Remote software development, ICON Worldwide Blog, Invest" title="ICON Worldwide Blog, Remote software development, Invest"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">29 June 2020</div>
                    <h5>8 Reasons Why You Should Invest in Remote Software Development [in 2021]</h5>
                    <p>The coronavirus crisis has reshaped the economic environment and its priorities.</p>
                </Link>
            </div>
            
            <div className="blog-post">
                <Link className="article" to="/blog/zero-party-data">
                    <img src={insights} className="article-img article-img-height" alt="Nearshore software development, ICON Worldwide Blog, Insights" title="ICON Worldwide Blog, Nearshore software development, Insights"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">27 May 2020</div>
                    <h5>ICON Insights: Brand Communities & Zero Party Data</h5>
                    <p>As business emerges into a new reality of remote customer relationships, a new type of brand community is emerging.</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/the-future-of-your-customer-relationship">
                    <img src={theFuture} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, the future" title="ICON Worldwide Blog, Nearshore software development, the future"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">28 April 2020</div>
                    <h5>The Future of Customer Relationships is Remote</h5>
                    <p>While most of us have successfully transitioned to working from the home office,  the end of face-to-face meetings is the next challenge.</p>
                </Link>
            </div>
          
            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/security-checklist-for-developers">
                    <img src={security} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Security" title="ICON Worldwide Blog, Nearshore software development, Security"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">8 November 2019</div>
                    <h5>Security Checklist for Developers</h5>
                    <p>Security should be foreseen as part of the system from the very beginning, not added as a layer at the end.</p>
                </Link>
            </div>

           
            <div className="blog-post">
                <Link className="article" to="/blog/sofia-nearshore-development-team">
                    <img src={sofiaTeam} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Sofia" title="ICON Worldwide Blog, Nearshore software development, Sofia"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">9 September 2019</div>
                    <h5>OUR SOFIA TEAM</h5>
                    <p>An inspiring week with the ICON Sofia team! I really encourage all companies considering out/near-shore development scenarios to get on the plane and be sure you know the people writing your code.</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/successful-personalised-nearshore-development">
                    <img src={sofia} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Nearshore Development" title="ICON Worldwide Blog, Nearshore software development, Nearshore Development"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">12 August 2019</div>
                    <h5>How to Succeed in Personalised Nearshore Development (and why you should opt for it!)</h5>
                    <p>Drupal, Magnolia CMS, WordPress, iOS and Android Development</p>
                </Link>
            </div>
          
            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/software-development-life-cycle-steps">
                    <img src={yahoo} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Top Steps" title="ICON Worldwide Blog, Nearshore software development, Top Steps"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">9 September 2019</div>
                    <h5>Understanding the top steps to follow within a software development life cycle</h5>
                    <p>ICON Ranked 4th for Software Development Best Practices and Processes</p>
                </Link>
            </div>
            
            <div className="blog-post">
                <Link className="article" to="/blog/number-20-for-php-development">
                    <img src={icon} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, PHP" title="ICON Worldwide Blog, Nearshore software development, PHP"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">26 July 2019</div>
                    <h5>ICON Worldwide ranks number 20 for PHP development</h5>
                    <p>According to the official 2019 software development company rankings, ICON Worldwide ranks number 20 for PHP development</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/top-20-for-healthcare-software">
                    <img src={abc} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Healthcare" title="ICON Worldwide Blog, Nearshore software development, Healthcare"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">22 July 2019</div>
                    <h5>ICON Worldwide ranks in Global Top 20 for Healthcare Softeware</h5>
                    <p>ICON Worldwide is proud to announce its ranking of number 14 globally and only Swiss agency to rank.</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
          
            <div className="blog-post">
                 <Link className="article" to="/blog/pharma-digital-marketing-case-studies">
                    <img src={ida} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Pharma" title="ICON Worldwide Blog, Nearshore software development, Pharma" />
                    <div className="yellow-line"></div>
                    <div className="article-date">13 March 2019</div>
                    <h5>Pharma and Rare Disease Design and Digital Marketing Case Studies</h5>
                    <p>Recent Healthcare & Pharma Case studies.</p>
                </Link>
            </div>

            <div className="blog-post">
                <Link className="article" to="/blog/how-to-improve-digital-marketing-roi">
                    <img src={roi} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Digital marketing" title="ICON Worldwide Blog, Nearshore software development, Digital marketing"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">26 February 2019</div>
                    <h5>How You Can Improve Your Digital Marketing ROI with a Multi-Channel Strategy </h5>
                    <p>The combined effect of integrated digital marketing can be best explained by the idea of supporting the customer journey.</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/how-gdpr-changed-seo-content-marketing">
                    <img src={capturingLead} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, GDPR" title="ICON Worldwide Blog, Nearshore software development, GDPR"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">17 January 2019</div>
                    <h5>How GDPR has changed SEO Content Marketing and SEM/PPC [and why you should care] </h5>
                    <p>Capturing leads in a post-GDPR world demands a highly synchronized customer journey.</p>
                </Link>
            </div>
           
            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/iron-deficiency-day-2018">
                    <img src={idDay} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Iron Deficiency Day" title="ICON Worldwide Blog, Nearshore software development, Iron Deficiency day"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">7 December 2018</div>
                    <h5>Digital Pharma: Vifor Pharma led Iron Deficiency Day 2018 Raises Awareness Via Multi-Channel Program Across 22 Countries.</h5>
                    <p>In its' third year, Iron Deficiency Day continued to gain traction on an increasingly global scale reaching over 22 countries.</p>
                </Link>
            </div>
            
            <div className="blog-post">
                <Link className="article" to="/blog/how-to-lead-ai-iot-blockchain-projects">
                    <img src={ourApproach} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, how to lead" title="ICON Worldwide Blog, Nearshore software development, how to lead"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">18 September 2018</div>
                    <h5>How to Lead Enterprise Scale AI, IoT and Blockchain Projects.</h5>
                    <p>How to Lead Enterprise Scale AI, IoT and Blockchain Projects.</p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

            <div className="blog-post">
                <Link className="article" to="/blog/icon-bulgaria-nearshore-development">
                    <img src={iconBG} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, the future" title="ICON Worldwide Blog, Nearshore software development, the future"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">14 May 2018</div>
                    <h5>The Future of Nearshore Development is Built Here [let's introduce ICON Bulgaria!]</h5>
                    <p>In January, 2018 ICON Worldwide opened a dedicated Development Center in Sofia, Bulgaria</p>
                </Link>
            </div>
            
            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            
            <div className="blog-post">
                <Link className="article" to="/blog/applying-ai-technologies-to-rare-disease-diagnosis">
                    <img src={AlConnects} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, AI Technologies" title="ICON Worldwide Blog, Nearshore software development, AI Technologies"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">11 May 2018</div>
                    <h5>Digital Pharma: Applying AI Technologies for Rare Disease Diagnosis</h5>
                    <p>We have created several tools which are already in use which have the potential for massive improvement using AI technologies. </p>
                </Link>
                </div>
                
                <div className="blog-post">
                <Link className="article" to="/blog/sams-app-development-case-study">
                    <img src={Sams} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Sams" title="ICON Worldwide Blog, Nearshore software development, Sams"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">19 December 2017</div>
                    <h5>Digital Pharma: The Actelion SAMS App. for Mobile Eye Exam for Rare Disease Discovery</h5>
                    <p>We developed the SAMS, Saccades Analysis Made Simple, application as part of a clinical study conducted by medical expert centers.</p>
                </Link>
                </div>

                <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

                <div className="blog-post">
                <Link className="article" to="/blog/zielabi-app-development-case-study">
                    <img src={ZielAbi} className="article-img" alt="Nearshore software development, ICON Worldwide Blog, Frankfurt School" title="ICON Worldwide Blog, Nearshore software development, Frankfurt School"/>
                    <div className="yellow-line"></div>
                    <div className="article-date">6 November 2017</div>
                    <h5>Frankfurt School of Finance & Management – Mobile iOS & Android App Development Case Study</h5>
                    <p>Developed for the Frankfurt School of Finance & Management, the ZielAbi app is a native iOS & Android phone application for Abitur tracking. </p>
                </Link>
            </div>

            <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
          
        </div>
    </div>

    <Footer noScrollbar="true"/>
</div>
